<template>
  <div>
    <lottie-player
      id="check-answers"
      v-show="firstGif"
      autoplay
      loop
      src="https://lottie.host/6741c472-0c8b-405c-9a55-9774802c03cf/b4qO5ffnfd.json"
      style="height: auto"
    />
    <lottie-player
      id="getting-docs"
      v-show="secondGif"
      autoplay
      loop
      src="https://lottie.host/2bec46eb-eccb-48f3-9dcd-20ca0a88f911/qRJmqkcL4T.json"
      style="height: auto"
    />
    <lottie-player
      id="setup-portal"
      v-show="thirdGif"
      autoplay
      loop
      src="https://lottie.host/371008c5-7566-425d-9fa4-5e4fbd07fe63/qrJzAXeOI2.json"
      style="height: auto"
    />
    <div class="setting-up-portal-content">
      <h2>{{ dynamicText }}</h2>
      <p>
        You have completed your mortgage application!<br />
        We are reviewing now for the next steps.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CryptoJS from "crypto-js";
import Shades from "./../mixins/shades";
import "@lottiefiles/lottie-player";

export default {
  name: "Setting-Up-Portal",
  mixins: [Shades],
  props: {
    cpUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      firstGif: true,
      secondGif: false,
      thirdGif: false,
      dynamicText: "Checking your answers...",
      timer: 0,
    };
  },
  computed: {
    ...mapGetters(["getAssets"]),
  },
  watch: {
    cpUrl: {
      handler(newVal) {
        if (newVal) {
          this.setTheTimer();
        }
      },
    },
    timer: {
      immediate: true,
      handler(newVal) {
        if (newVal > 0.6 && newVal <= 8) {
          this.firstGif = false;
          this.secondGif = true;
          this.dynamicText = "Generating items needed...";
        } else if (newVal > 8.1) {
          this.secondGif = false;
          this.thirdGif = true;
          this.dynamicText = "Setting up personal portal...";
        }
      },
    },
  },
  mounted() {
    let check_ans = document.getElementById("check-answers");
    let get_docs = document.getElementById("getting-docs");
    let set_portal = document.getElementById("setup-portal");

    this.$nextTick(() => {
      if (check_ans.shadowRoot) this.addDynamicColorToGif(check_ans, "first");
      if (get_docs.shadowRoot) this.addDynamicColorToGif(get_docs, "second");
      if (set_portal.shadowRoot) this.addDynamicColorToGif(set_portal, "third");
    });
  },
  methods: {
    ...mapActions(["fetchLoaderStatus"]),

    addDynamicColorToGif(element, sequence) {
      let dark_shade = this.getShadesOfColor(
        this.getAssets.primaryBrandColor || "#A6383C",
        -0.1
      );

      if (sequence === "first") {
        element.shadowRoot.querySelector(".animation").innerHTML = `<style>
              svg > g > g:nth-child(10) > g > path:last-child{
                  stroke: ${this.getAssets.primaryBrandColor || "#A6383C"};
              }
              svg > g > g:nth-child(11) > g > path,
              svg > g > g:nth-child(12) > g > path,
              svg > g > g:nth-child(13) > g > path,
              svg > g > g:nth-child(15) > g > path{
                  stroke: ${dark_shade};
              }
              svg > g > g:nth-child(9) > g > path,
              svg > g > g:nth-child(14) > g > path {
                  stroke: ${this.getAssets.primaryBrandColor || "#A6383C"};
              }
          </style>`;
      }

      if (sequence === "second") {
        element.shadowRoot.querySelector(".animation").innerHTML = `<style>
              svg > g > g:nth-child(10) > g > path{
                  fill: ${this.getAssets.primaryBrandColor || "#A6383C"};
              }
              svg > g > g:nth-child(17) > g > path:first-child{
                  fill: ${
                    this.getAssets.primaryBrandColor || "#A6383C"
                  } !important;
              }
              svg > g > g:nth-child(11) > g > path:last-child{
                  stroke: ${this.getAssets.primaryBrandColor || "#A6383C"};
              }
              svg > g > g:nth-child(14) > g > path {
                  stroke: ${this.getAssets.primaryBrandColor || "#A6383C"};
              }
          </style>`;
      }

      if (sequence === "third") {
        element.shadowRoot.querySelector(".animation").innerHTML = `<style>
              svg > g > g:nth-child(2) > g > path,
              svg > g > g:nth-child(10) > g > path{
                  fill: ${this.getAssets.primaryBrandColor || "#A6383C"};
              }
          </style>`;
      }
    },

    setTheTimer() {
      let startTime = Date.now();

      let theTimer = setInterval(() => {
        if (this.timer > 15) {
          clearInterval(theTimer);
          if (this.cpUrl) window.location.href = this.aesDecryption(this.cpUrl);
        } else {
          this.timer = ((Date.now() - startTime) / 1000).toFixed(2);
        }
      }, 100);
    },

    aesDecryption(encrypted) {
      let iv = CryptoJS.enc.Utf8.parse("qazwsxedcrfvtgby"); //key used in Python/Salesforce
      let key = CryptoJS.enc.Utf8.parse("zaqxswcdevfrbgtn"); //key used in Python/Salesforce

      return CryptoJS.AES.decrypt(encrypted, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
      }).toString(CryptoJS.enc.Utf8);
    },
  },
};
</script>

<style scoped>
.extra-wrapper-css {
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.setting-up-portal-content {
  margin-top: 15px !important;
}

.setting-up-portal-content h2 {
  color: #000;
  text-align: center;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.setting-up-portal-content p {
  color: #000;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  opacity: 0.7;
  margin-top: 24px;
}
</style>