export default {
    data() {
        return {
            clientDevice: "",
            clientBrowser: ""
        }
    },
    methods: {
        fetchUserDeviceBrowser() {
            const userAgent = navigator.userAgent;

            // Get user browser
            if (userAgent.match(/chrome|crios/i)) {
                this.clientBrowser = "Chrome";
            } else if (userAgent.match(/chromium/i)) {
                this.clientBrowser = "Chromium";
            } else if (userAgent.match(/firefox|fxios/i)) {
                this.clientBrowser = "Firefox";
            } else if (userAgent.match(/safari/i)) {
                this.clientBrowser = "Safari";
            } else if (userAgent.match(/opr\//i)) {
                this.clientBrowser = "Opera";
            } else if (userAgent.match(/edg/i)) {
                this.clientBrowser = "Edge";
            } else {
                this.clientBrowser = "Unknown";
            }

            // Get user device
            if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
                this.clientDevice = "Tablet";
            } else if (/Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(userAgent)
            ) {
                this.clientDevice = "Mobile";
            } else {
                this.clientDevice = "Desktop";
            }
        }
    }
}